import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <h2>Seasons</h2>
      <div className="row justify-content-center subsection index-item-container">
        {data.allSeasondataJson.edges.map((edge, key) => (
          <div key={key} className="col-12 col-md-6 col-lg-3 index-item center">
            <Link to={`/season/${edge.node.sid}`}>
              <span className="index-item-label">{edge.node.label}</span>
            </Link>
          </div>
        ))}
      </div>
      <h2>Competitions</h2>
      <div className="row justify-content-center subsection index-item-container">
        {data.allMysqlCompetitions.edges.map((edge, key) => (
          <div key={key} className="col-12 col-md-6 col-lg-3 index-item">
            <Link to={`/competition/${edge.node.mysqlId}`}>
              <span className="index-item-label">
                {edge.node.label || edge.node.short}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMysqlCompetitions {
      edges {
        node {
          mysqlId
          label
          logo
          short
        }
      }
    }
    allSeasondataJson {
      edges {
        node {
          sid
          label
        }
      }
    }
  }
`
